var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import globalConstants from 'app/constants/global-constants';
import testingHelpers from 'app/helpers/testingHelpers';
import { FETCH_SANDBOX, FETCH_SANDBOX_SUCCESS, FETCH_SANDBOX_FAILED, CREATE_SANDBOX, CREATE_SANDBOX_SUCCESS, CREATE_SANDBOX_FAILED, POLL_SANDBOX, POLL_SANDBOX_SUCCESS, POLL_SANDBOX_FAILED, GET_USE_CASES, GET_USE_CASES_SUCCESS, GET_USE_CASES_FAILED, } from './constants';
export default (function (state, action) {
    if (state === void 0) { state = {}; }
    // If dev env, data filter might apply for testing purposes before redux state is updated
    if (globalConstants.ENV === 'dev') {
        action = testingHelpers.dataFilter(action);
    }
    switch (action.type) {
        // ===== GET USE CASES =====
        case GET_USE_CASES:
            return __assign(__assign({}, state), { isGettingUseCases: true, isUsecaseGettingError: false });
        case GET_USE_CASES_SUCCESS:
            return __assign(__assign({}, state), { useCases: action.useCases, isGettingUseCases: false, isUsecaseGettingError: false });
        case GET_USE_CASES_FAILED:
            return __assign(__assign({}, state), { isGettingUseCases: false, isUsecaseGettingError: true });
        // ===== FETCH SANDBOX =====
        case FETCH_SANDBOX:
            return __assign(__assign({}, state), { isFetching: true });
        case FETCH_SANDBOX_SUCCESS:
            var sandboxRunningInstances = action.sandbox.sort(function (a, b) { return a.createdAt > b.createdAtx; });
            return __assign(__assign({}, state), { isFetching: false, items: sandboxRunningInstances, error: null });
        case FETCH_SANDBOX_FAILED:
            return __assign(__assign({}, state), { isFetching: false, error: action.error });
        // ===== CREATE SANDBOX =====
        case CREATE_SANDBOX:
            return __assign(__assign({}, state), { isCreating: true, creatingUseCase: action.usecase, isCreatingError: false });
        case CREATE_SANDBOX_SUCCESS: {
            var newItems = __spreadArray([], state.items, true);
            // if the usecase already exist, then find its index and replace it
            var existingIndex = state.items.findIndex(function (i) { return i.usecase === action.sandbox.usecase; });
            if (existingIndex > -1) {
                newItems[existingIndex] = action.sandbox;
            }
            else {
                newItems.unshift(action.sandbox);
            }
            var shouldPoll = newItems.find(function (i) { return i.status === 'PENDING'; });
            return __assign(__assign({}, state), { items: newItems, isCreating: false, isCreatingError: false, creatingUseCase: null, lastCreatedSandboxHash: action.sandbox.sandboxHashKey, shouldPoll: !!shouldPoll });
        }
        case CREATE_SANDBOX_FAILED:
            return __assign(__assign({}, state), { isCreating: false, creatingUseCase: null, isCreatingError: true, error: action.error });
        // ===== POLL SANDBOX =====
        case POLL_SANDBOX:
            return __assign(__assign({}, state), { isPolling: true, shouldPoll: null });
        case POLL_SANDBOX_SUCCESS:
            return __assign(__assign({}, state), { isPolling: false, items: action.sandbox, lastCreatedSandboxHash: null });
        case POLL_SANDBOX_FAILED:
            return __assign(__assign({}, state), { isPolling: false, lastCreatedSandboxHash: null, error: action.error });
        // ===== TERMINATE SANDBOX =====
        case 'TERMINATE_SANDBOX':
            return __assign(__assign({}, state), { isTerminating: true, terminateSandboxHashKey: action.sandboxHashKey, isTerminatingError: null });
        case 'TERMINATE_SANDBOX_SUCCESS': {
            var items = __spreadArray([], state.items, true);
            var toRemoveIndex = items.findIndex(function (i) { return i.sandboxHashKey === state.terminateSandboxHashKey; });
            if (toRemoveIndex > -1) {
                items.splice(toRemoveIndex, 1);
            }
            return __assign(__assign({}, state), { isTerminating: false, terminateSandboxHashKey: null, isTerminatingError: false, items: items });
        }
        case 'TERMINATE_SANDBOX_FAILED':
            return __assign(__assign({}, state), { isTerminating: false, isTerminatingError: true, terminateSandboxHashKey: null, error: action.error });
        // ===== EXTEND SANDBOX =====
        case 'EXTEND_SANDBOX':
            return __assign(__assign({}, state), { extendSandboxHashKey: action.extendSandboxHashKey, isExtending: true, isExtendingError: null });
        case 'EXTEND_SANDBOX_SUCCESS':
            return __assign(__assign({}, state), { extendSandboxHashKey: null, isExtending: false, isExtendingError: false, items: action.sandboxes });
        case 'EXTEND_SANDBOX_FAILED':
            return __assign(__assign({}, state), { extendSandboxHashKey: null, isExtending: false, isExtendingError: true, error: action.error });
        // ===== INVITE_TO SANDBOX =====
        case 'INVITE_TO_SANDBOX':
            return __assign(__assign({}, state), { isSharing: true, isSharingError: null });
        case 'INVITE_TO_SANDBOX_SUCCESS':
            var inviteData_1 = action.inviteData;
            var sandboxItems = __spreadArray([], state.items, true);
            if (inviteData_1) {
                sandboxItems = sandboxItems.map(function (s) {
                    if (s.sandboxHashKey === inviteData_1.sandboxHashKey) {
                        s.pendingInvites.push({ email: inviteData_1.inviteEmail });
                    }
                    return s;
                });
            }
            return __assign(__assign({}, state), { items: sandboxItems, isSharing: false, isSharingError: false });
        case 'INVITE_TO_SANDBOX_FAILED':
            return __assign(__assign({}, state), { isSharing: false, isSharingError: true, error: action.error });
        case 'ACCEPT_INVITATION':
            return __assign(__assign({}, state), { isAcceptingInvitation: true, isAcceptingInvitationError: null });
        case 'ACCEPT_INVITATION_SUCCESS':
            return __assign(__assign({}, state), { isAcceptingInvitation: false, isAcceptingInvitationError: null, test: 1 });
        case 'ACCEPT_INVITATION_FAILED':
            return __assign(__assign({}, state), { isAcceptingInvitation: false, isAcceptingInvitationError: true, error: action.error });
        default:
            return state;
    }
});
